<template>
  <v-card
    class="text-center"
  >
    <v-card-title> {{ $t('ScanPoint') }} </v-card-title>
    <v-card-text>
      <QRCode
        v-model="data"
        class="qrcode"
      />
    </v-card-text>
    <v-btn
      class="mt-4 mb-8"
      color="warning"
      @click="$router.back()"
    >
      {{ $t('Back') }}
    </v-btn>
    <v-dialog
      v-model="isDialogVisible"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('CouponConfirm') }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <div>
              {{ $t('CustomerId') }}: <strong>{{ qrData.id_user }}</strong>
            </div>
            <div class="mt-2">
              {{ $t('Coupon') }}: <strong>{{ qrData.name }}</strong>
            </div>
            <div v-if="!expired(qrData.expired_at) && !qrData.used_at">
              <v-text-field
                v-model="orderCode"
                dense
                outlined
                :label="$t('OrderID')"
                :placeholder="$t('OrderID')"
                :prepend-inner-icon="icons.mdiClipboardCheckOutline"
                hide-details="auto"
                class="my-4"
                validate-on-blur
                :rules="[validators.required]"
                value="P-1235"
              ></v-text-field>
            </div>
            <div
              v-if="expired(qrData.expired_at) || qrData.used_at"
              class="mt-2"
            >
              <div
                v-if="expired(qrData.expired_at)"
                class="text-lefterror--text"
              >
                {{ $t('ExpirationDate') }}: <strong>{{ formatTimestampToDatetime(qrData.expired_at) }}</strong>
              </div>
              <div
                v-if="qrData.is_used"
                class="text-left success--text"
              >
                {{ $t('UsedDate') }}: <strong>{{ formatTimestampToDatetime(qrData.used_at) }}</strong>
              </div>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn
                v-if="!expired(qrData.expired_at) && !qrData.used_at"
                color="primary"
                block
                @click="UseCoupon"
              >
                {{ $t('Save') }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="error"
                outlined
                block
                @click="isDialogVisible = false"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import QRCode from '@/components/QRCode.vue'
import { ref, watch } from '@vue/composition-api'
import { mdiClipboardCheckOutline } from '@mdi/js'
import { required } from '@core/utils/validation'
import CafeService from '@/services/CafeService'
import { useRouter } from '@core/utils'
import dayjs from 'dayjs'

export default {
  components: { QRCode },
  setup() {
    const { router } = useRouter()
    const form = ref(null)
    const data = ref('')
    const isDialogVisible = ref(false)
    const qrData = ref({})

    const orderCode = ref('')
    const expired = val => !!val && dayjs.unix(val).valueOf() < Date.now()

    const ScanCoupon = async () => {
      if (data.value) {
        const res = await CafeService.staffScanCoupon(data.value)
        qrData.value = res.data.data
        isDialogVisible.value = true
      }
    }

    const UseCoupon = async () => {
      // const isFormValid = form.value.validate()
      // if (isFormValid) {
      await CafeService.staffUseCoupon({
        order_code: orderCode.value,
        code_coupon: qrData.value.code_coupon,
      })
      router.back()

      // }
    }

    watch(data, () => {
      ScanCoupon()
    })

    return {
      data,
      isDialogVisible,
      qrData,

      orderCode,

      // Refs
      form,

      ScanCoupon,
      UseCoupon,
      expired,
      icons: { mdiClipboardCheckOutline },
      validators: {
        required,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.qrcode {
  border: 2px solid var(--v-primary-base);
  padding: 2px;
}
</style>
